import type { Timestamp } from 'firebase-admin/firestore'
import { isString } from './utils'

export interface StoreDeviceLink {
  deviceType: string
  linkCode: string
  deviceId: string
  pollingInterval?: number
  created: Timestamp
  expires: Timestamp
  uid?: string
  linkUrl: string
  directLinkUrl?: string
}

export interface GenerateLinkCodeInput {
  deviceType: StoreDeviceLink['deviceType']
  deviceId?: StoreDeviceLink['deviceId']
}

export interface LinkDeviceToUserPayload {
  uid: string
  linkCode: string
}
export type LinkPollResponse = LinkPollUpdateCode | LinkPollSuccess

export function isLinkPollUpdateCode(
  result: LinkPollResponse,
): result is LinkPollUpdateCode {
  return isString((result as LinkPollUpdateCode).linkCode)
}
export function isLinkPollSuccess(
  result: LinkPollResponse,
): result is LinkPollSuccess {
  return isString((result as LinkPollSuccess).token)
}
export type LinkDeviceToUserInput = Omit<LinkDeviceToUserPayload, 'uid'>

export interface UserDeviceLink extends Omit<StoreDeviceLink, 'created'> {
  linked: Timestamp
}

export interface LinkPollInput {
  deviceId: string
  linkCode: string
}

export interface LinkPollUpdateCode {
  linkCode: string
}

export interface LinkPollSuccess {
  token: string
}
