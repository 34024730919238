export * from './OdinGridResponse'
export * from './SearchResult'
export * from './show'

export enum MediaMimeTypes {
  '.bmp' = 'image/bmp',
  '.gif' = 'image/gif',
  '.ico' = 'image/vnd.microsoft.icon',
  '.jpeg' = 'image/jpeg',
  '.jpg' = 'image/jpeg',
  '.mp3' = 'audio/mpeg',
  '.mpeg' = 'video/mpeg',
  '.oga' = 'audio/ogg',
  '.ogv' = 'video/ogg',
  '.ogx' = 'application/ogg',
  '.opus' = 'audio/opus',
  '.otf' = 'font/otf',
  '.png' = 'image/png',
  '.svg' = 'image/svg+xml',
  '.tif' = 'image/tiff',
  '.tiff' = 'image/tiff',
  '.ts' = 'video/mp2t',
  '.wav' = 'audio/wav',
  '.weba' = 'audio/webm',
  '.webm' = 'video/webm',
  '.webp' = 'image/webp',
}
