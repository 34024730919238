import { ContentItem } from '@adiffengine/engine-types'

export const saveToWatchlist = async function (
  _content: ContentItem,
  _persist = false,
) {
  console.warn(' Save to watch list not implemented')
}

// Hi!
