import { ImageSet } from '@adiffengine/engine-types'
import isNumber from 'lodash-es/isNumber'
import isString from 'lodash-es/isString'

import { Settings, Utils } from '@lightningjs/sdk'
import { MediaMimeTypes } from './types'
import { OdinImage } from './types/show'

const validMimeTypes = Object.values(MediaMimeTypes)
interface ImageUploadFormat {
  ext: string
  url: string
  hash: string
  mime: MediaMimeTypes
  name: string
  path: string | null
  size: number
  width: number
  height: number
}
type FormatSet = Record<string, ImageUploadFormat>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isImageUploadFormat(x: any): x is ImageUploadFormat {
  return x && isString(x.url) && isNumber(x.width)
}

function getFormatSet(x: OdinImage): FormatSet {
  if (!x || !x.formats) return {}
  else {
    return Object.entries(x.formats).reduce((acc, [key, value]) => {
      if (isImageUploadFormat(value)) {
        acc[key] = value
      }
      return acc
    }, {} as FormatSet)
  }
}
export interface OdinImageSetOptions {
  baseURL: string | null
}
const defaultOptions: OdinImageSetOptions = {
  baseURL: null,
}
export class OdinImageSet implements ImageSet {
  private _options: OdinImageSetOptions
  private _file: OdinImage
  private _formats: Pick<ImageUploadFormat, 'width' | 'url'>[] = []

  get sizes() {
    return this._formats.map(s => s.width)
  }

  constructor(file: OdinImage, options: Partial<OdinImageSetOptions> = {}) {
    this._options = { ...defaultOptions, ...options }
    this._file = file
    const formats = getFormatSet(file)
    this._formats = Object.values(formats).sort((a, b) => b.width - a.width)
  }

  fullUrl(url: string): string {
    let out: string = url
    try {
      if (url.indexOf('http') !== 0) {
        console.info('Base url %s path: %s', this._options.baseURL)
        out =
          this._options.baseURL !== null &&
          this._options.baseURL.indexOf('http') === 0 &&
          url.indexOf('http') !== 0
            ? new URL(url, this._options.baseURL).toString()
            : url
      }
    } catch (error) {
      console.warn('Error getting full url %s', error.message, error)
    }
    const needsProxy = Settings.get('app', 'NEEDS_PROXY', false)
    return needsProxy ? Utils.proxyUrl(out) : out
  }
  getUrl(size: string) {
    const image = this._formats.find(x => x.width === parseInt(size, 10))
    return image?.url ? this.fullUrl(image.url) : null
  }

  getForWidth(targetWidth: number): string | null {
    if (this._formats.length === 0) return null
    if (this._formats.length === 1) return this._formats[0].url
    else {
      const [first, ...rest] = this._formats
      let result = first
      rest.forEach(format => {
        if (format.width > targetWidth && format.width <= result.width)
          result = format
      })
      return this.fullUrl(result.url)
    }
  }
  getBiggest() {
    if (this._formats.length === 0) return null
    if (this._formats.length === 1) return this._formats[0].url
    const sorted = this._formats.sort((a, b) => b.width - a.width)
    return this.fullUrl(sorted[0].url)
  }

  toJSON() {
    return this._file
  }
}
