import { ContentSource } from '@adiffengine/engine-types'
import { Settings } from '@lightningjs/sdk'

import { isGoodArray } from '@adiffengine/elements'
import { OdinSdk } from '@adiffengine/odin-sdk'

export function getSdk(): OdinSdk {
  const APP_ODIN_BASE_URL = Settings.get(
    'app',
    'api_url',
    'http://cms.adeengine.com/api/valhalla-api',
  )

  const odinClient = new OdinSdk(APP_ODIN_BASE_URL, {
    clientOptions: {
      params: {
        stubContent: true,
      },
    },
  })
  return odinClient
}
export function getAppropriateSource(
  sources?: ContentSource[],
): ContentSource | null {
  if (isGoodArray<ContentSource>(sources)) {
    const hls = sources?.find(({ type }) => type === 'hls')
    const dash = sources?.find(({ type }) => type === 'dash')
    const mp4 = sources?.find(({ type }) => type === 'mp4')
    return hls ?? dash ?? mp4 ?? null
  } else {
    return null
  }
}
