import { ThorError, isGoodArray, isValidDate } from '@adiffengine/elements'
import {
  CastMember,
  ContentEpisode,
  ContentItem,
  ContentSeason,
  EpisodicSdk,
  GridContext,
  GridResponse,
  ID,
  MediaDetails,
  MediaDetailsCore,
  MediaDetailsExtra,
  MediaImages,
  MediaLookupType,
  TheGridRowData,
} from '@adiffengine/engine-types'
import axios, { AxiosInstance, AxiosResponse, CreateAxiosDefaults } from 'axios'
import isString from 'lodash-es/isString'
import { OdinImageSet } from './odin-image-set'
import {
  Content,
  Grid,
  GridConvert,
  Media,
  OdinCast,
  OdinGridResponse,
  Type,
  isOdinCast,
} from './types/OdinGridResponse'
import { OdinPerson } from './types/Person'
import { ContentItemSearchResult } from './types/SearchResult'
import { OdinEpisode, OdinSeason } from './types/show'

function guid(): string {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  )
}

export interface SearchResult {
  hits: ContentItemSearchResult[]
}
export interface SearchPost {
  q: string
}
function searchClient({
  host,
  apiKey,
}: {
  host: string
  apiKey: string
}): AxiosInstance {
  return axios.create({
    baseURL: host,
    headers: {
      Authorization: `Bearer ${apiKey}`,
    },
  })
}
export interface OdinApiClientOptions {
  searchHost: string
  searchClientKey: string
  populateMedia: boolean
  populateAds: boolean
  baseImageUrl: string | null
  clientOptions: CreateAxiosDefaults
}

function getExipiresTime(seconds = 5000) {
  const d = new Date()
  d.setSeconds(d.getSeconds() + seconds)
  return d
}

export class OdinSdk implements EpisodicSdk {
  private _client: AxiosInstance
  private _searchClient: AxiosInstance
  private _options: OdinApiClientOptions = {
    searchHost: 'https://odin-search.adeengine.com/',
    searchClientKey:
      'fb9313c6358555b9c567fa26aa912223d0ac5134b09921de19b342a1a860f100',
    populateMedia: false,
    populateAds: false,
    baseImageUrl: null,
    clientOptions: {},
  }

  constructor(baseURL: string, options: Partial<OdinApiClientOptions> = {}) {
    this._options = { ...this._options, ...options }
    this._client = axios.create({
      baseURL,
      ...this._options.clientOptions,
    })
    if (this._options.baseImageUrl === null) {
      this._options.baseImageUrl = baseURL
    }
    this._searchClient = searchClient({
      host: this._options.searchHost,
      apiKey: this._options.searchClientKey,
    })
  }

  convertOdinEpisodeToContentEpisode(episode: OdinEpisode): ContentEpisode {
    const {
      air_date,
      episode_id,
      season_number,
      image,
      media = [],
      ...rest
    } = episode
    const air = isString(air_date) ? new Date(air_date) : undefined
    return {
      episodeId: episode_id,
      ...rest,

      image: image
        ? new OdinImageSet(image, {
            baseURL: this._options.baseImageUrl,
          })
        : undefined,
      air_date: isValidDate(air) ? air : undefined,
    }
  }
  convertCastItem(cast: OdinPerson | OdinCast): CastMember {
    const {
      name,
      image,
      birthday,
      cast_id,
      biography = '',
      place_of_birth,
    } = cast
    return {
      biography,
      id: cast_id,
      cast_id,
      place_of_birth: place_of_birth == null ? undefined : place_of_birth,
      name,
      character: isOdinCast(cast) ? cast.character : '',
      birthday,
      profile: new OdinImageSet(image, {
        baseURL: this._options.baseImageUrl,
      }),
    }
  }

  private async fetch<ReturnType, ResponseType = unknown>(
    path: string,
    converter?: (x: ResponseType) => ReturnType,
  ): Promise<ReturnType> {
    if (converter) {
      return this._client
        .get<unknown, AxiosResponse<ResponseType>>(path)
        .then(r => converter(r.data))
    } else {
      return this._client
        .get<unknown, AxiosResponse<ReturnType>>(path)
        .then(r => r.data)
    }
  }

  async preload() {
    await this.grid({ params: { type: 'all' } })
  }

  private _mainGrid: {
    expires: Date
    response: GridResponse
  } | null = null

  async grid(context: GridContext): Promise<GridResponse> {
    if (
      this._mainGrid !== null &&
      this._mainGrid.expires.getTime() < new Date().getTime()
    )
      return this._mainGrid.response
    try {
      const {
        params: { type },
      } = context
      const response = await this.fetch<OdinGridResponse>(
        `grid/${type}`,
        GridConvert.parsedToGridResponse,
      )
      let heros: GridResponse['heros'] | undefined = undefined
      if (response.heros) {
        const { content, ...rest } = response.heros
        heros = {
          ...rest,
          items: this.parseContentList(response.heros.content),
        }
      }
      this._mainGrid = {
        expires: getExipiresTime(),
        response: {
          context,
          requestId: guid(),
          grid: response.grid.map(this.parseGridRow.bind(this)),
          heros,
        },
      }

      return this._mainGrid.response
    } catch (error) {
      throw new ThorError(error.message, ThorError.Type.GridError, {
        context,
        error,
      })
    }
  }
  async person(id: ID): Promise<CastMember | null> {
    try {
      const response = await this.fetch<CastMember, OdinPerson>(
        `cast/${id}`,
        this.convertCastItem.bind(this),
      )
      return response
    } catch (error) {
      console.warn('Could not get person for id %s', id)
      return null
    }
  }
  async details(id: ID) {
    try {
      const response = await this.fetch<ContentItem, Content>(
        `details/${id}`,
        this.convertOdinItemToContentItem.bind(this),
      )
      console.info('Response', response)
      return response
    } catch (error) {
      throw new ThorError(error.message, ThorError.Type.DetailsError, {
        id,
        error,
      })
    }
  }

  convertOdinSeasonToContentSeason(
    season: OdinSeason,
    show_id: ID,
  ): ContentSeason {
    console.info('Converting season', season)
    const { air_date, episodes = [], ...rest } = season
    const date = air_date !== undefined ? new Date(air_date) : air_date
    return {
      ...rest,
      show_id,
      episodes: episodes.map(
        this.convertOdinEpisodeToContentEpisode.bind(this),
      ),
      air_date: date,
    }
  }

  async seasonDetails(
    contentId: ID,
    seasonId?: ID | undefined,
  ): Promise<ContentSeason> {
    try {
      const path =
        contentId && seasonId
          ? `show/${contentId}/season/${seasonId}`
          : `season/${contentId}`

      const response = await this.fetch<ContentSeason, OdinSeason>(
        path,
        response => this.convertOdinSeasonToContentSeason(response, contentId),
      )
      console.info('Season Response', response)
      return response
    } catch (error) {
      throw new ThorError(error.message, ThorError.Type.SeasonDetailsError, {
        contentId,
        seasonId,
        error,
      })
    }
  }
  async episodeDetails(
    episodeId?: ID | undefined,
  ): Promise<ContentEpisode | null> {
    try {
      const response = await this.fetch<ContentEpisode, OdinEpisode>(
        `episode/${episodeId}`,
        this.convertOdinEpisodeToContentEpisode.bind(this),
      )
      return response
    } catch (error) {
      throw new ThorError(error.message, ThorError.Type.EpisodeDetailsError, {
        episodeId,
        error,
      })
    }
  }

  async search(q: string, min = 3): Promise<ContentItem[] | null> {
    if (q.length < min) return null
    // const indexes = await this._searchClient.get('indexes')
    // console.info('Indexes', indexes)
    // const contentItems = await this._searchClient.get('indexes/content-item')
    // console.info('Content Items', contentItems)
    try {
      const hits = await this._searchClient
        .post<SearchPost, AxiosResponse<SearchResult>>(
          'indexes/content-item/search',
          {
            q,
          },
        )
        .then(({ data }) => data.hits)

      if (hits && hits.length) {
        return this.convertSearchHitsToContentItems(hits)
      } else {
        return []
      }
    } catch (error) {
      throw new ThorError(error.message, ThorError.Type.SearchError, {
        error,
        q,
        min,
      })
    }
  }

  convertMedia(
    item: Media,
    sourceType: 'movie' | 'episode',
    sourceID: ID,
  ): MediaDetails {
    const {
      mux_source,
      url_source,
      extra = null,
      title,
      subtitle,
      description,
      button_text,
      id,
    } = item
    const extraDetails =
      extra === null
        ? ({
            type: sourceType,
          } as Pick<MediaDetailsCore, 'type'>)
        : ({
            type: 'extra',
            extra_type: extra,
          } as Pick<MediaDetailsExtra, 'type' | 'extra_type'>)
    const sources: MediaDetails['sources'] =
      Array.isArray(mux_source) && mux_source.length > 0
        ? mux_source.map(source => {
            return {
              src: `https://stream.mux.com/${source.asset.playback_id}.m3u8`,
              type: 'hls',
            }
          })
        : []
    const player =
      extra === null
        ? `player/${sourceType}/${sourceID}`
        : `player/${sourceType}/${sourceID}/extra/${id}`
    return {
      button_text,
      subtitle: subtitle === null ? undefined : subtitle,
      description: description === null ? undefined : description,
      id,
      title,
      sources,
      paths: {
        player,
      },
      ...extraDetails,
    }
  }

  convertOdinItemToContentItem(item: Content): ContentItem {
    const {
      wide_art,
      box_art,
      similar = [],
      seasons = [],
      recommended = [],
      release,
      media = [],
      cast = [],
      ...rest
    } = item
    const releaseDate = new Date(release)

    const imagesets: MediaImages = {}
    if (wide_art)
      imagesets.wide = new OdinImageSet(wide_art, {
        baseURL: this._options.baseImageUrl,
      })
    if (box_art)
      imagesets.box = new OdinImageSet(box_art, {
        baseURL: this._options.baseImageUrl,
      })
    const out: ContentItem = {
      ...rest,
      media: media.map(media =>
        this.convertMedia(
          media,
          rest.type === Type.Tv ? 'episode' : 'movie',
          rest.id,
        ),
      ),
      cast: cast.map(this.convertCastItem.bind(this)),
      similar: similar.map(this.convertOdinItemToContentItem.bind(this)),
      recommendations: recommended.map(
        this.convertOdinItemToContentItem.bind(this),
      ),
      seasons: seasons.map(s =>
        this.convertOdinSeasonToContentSeason(s, rest.id),
      ),
      release,
      paths: {
        details: `details/${item.type}/${item.id}`,
        player: `player/${item.type}/${item.id}`,
      },
      images: imagesets,
    }
    if (!isNaN(releaseDate.getTime())) {
      out.release = releaseDate
    }
    console.info('Returning out', JSON.stringify(out.seasons))
    return out
  }
  async getSource(
    contentItem: ContentItem,
    lookup: MediaLookupType,
  ): Promise<MediaDetails | null> {
    try {
      switch (lookup.type) {
        case 'movie':
          return contentItem.media?.find(({ type }) => type === 'movie') ?? null
        case 'episode': {
          const season = Array.isArray(contentItem.seasons)
            ? contentItem.seasons.find(
                ({ number }) => number === lookup.seasonNumber,
              )
            : null

          if (season && isGoodArray<ContentEpisode>(season.episodes)) {
            const episode = season.episodes.find(
              ({ episodeNumber }) => episodeNumber === lookup.episodeNumber,
            )
            if (episode && isGoodArray<MediaDetailsCore>(episode.media)) {
              const media = episode?.media?.find(
                ({ type }) => type === 'episode',
              )
              if (media != null) return media
            }
          }

          return this.episodeDetails(contentItem.id).then(episode => {
            const media = episode?.media?.find(({ type }) => type === 'episode')
            return media ?? null
          })
        }
        case 'extra': {
          const item = contentItem.media?.find(
            ({ type, id }) => type === 'extra' && id === lookup.id,
          )
          return item ?? null
        }
        default:
          return null
      }
    } catch (error) {
      throw new ThorError(error.message, ThorError.Type.BadData, {
        error,
        contentItem,
        lookup,
      })
    }
  }

  parseContentList(items: Content[]): ContentItem[] {
    return items.map(this.convertOdinItemToContentItem.bind(this))
  }
  parseGridRow(item: Grid): TheGridRowData {
    const { content, ...rest } = item
    return {
      ...rest,
      items: this.parseContentList(content),
    }
  }
  convertSearchHitsToContentItems(
    hits: ContentItemSearchResult[],
  ): ContentItem[] {
    return hits.map(this.convertSearchHitToContentItem.bind(this))
  }
  convertSearchHitToContentItem(hit: ContentItemSearchResult): ContentItem {
    // const { wide_art, box_art, tmdb_data } = hit
    return {
      ...hit,
      images: {},
      genres: hit.genres.map(g => ({ id: g.id, name: g.name })),
      paths: {
        details: `details/${hit.type}/${hit.id}`,
        player: `player/${hit.type}/${hit.id}`,
      },
    }
  }
}
