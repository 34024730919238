// To parse this data:
//
//   import { Convert, OdinGridResponse } from "./file";
//
//   const odinGridResponse = Convert.toOdinGridResponse(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

import { ID } from '@adiffengine/engine-types'

import isString from 'lodash-es/isString'
import { OdinPerson } from './Person'
import { OdinSeason } from './show'

export interface OdinGridResponse {
  context: Context
  grid: Grid[]
  heros: Grid
}

export interface UncastOdinGridResponse {
  context: Context
  grid: UncastGrid[]
  heros: UncastHeros
}
export interface Context {
  params: Params
}

export interface Params {
  type: string
}

export interface Grid {
  id: ID
  title: string
  content: Content[]
}
export interface UncastGrid {
  id: number
  name: string
  contents: UncastContent[]
}
export interface UncastContent
  extends Omit<Content, 'release' | 'recommended' | 'similar'> {
  release: string
  recommended?: null | UncastContent[]
  similar?: null | UncastContent[]
}

export interface OdinCast extends OdinPerson {
  character: string
}
export function isOdinCast(x: OdinPerson | OdinCast): x is OdinCast {
  return isString((x as OdinCast).character)
}

export interface Media {
  extra?: 'trailer' | 'extra' | 'recap' | 'preview' | null
  id: number
  ad_data: null
  button_text: string
  description: null
  title: string
  subtitle: null | string
  mux_source: MuxSource[]
  url_source: any[]
}
export interface MuxSource {
  id: number
  Type: string
  asset: Asset
}
export interface Asset {
  id: number
  title: string
  upload_id: null
  asset_id: string
  playback_id: string
  error_message: null
  isReady: boolean
  duration: number
  aspect_ratio: string
}
export interface Content {
  id: number
  title: string
  description: string
  type: Type
  popularity: number
  parental_rating: null
  adult: boolean
  tagline: string
  wide_art: Art | null
  box_art: Art
  recommended?: Content[]
  media?: Media[]
  cast?: OdinCast[]
  seasons?: OdinSeason[]
  similar: Content[]
  details: string
  genres: Genre[]
  release: Date
  tmdb_data?: any
}

export interface Art {
  id: number
  caption: null
  formats: Formats
  url: string
}

export interface Formats {
  large?: Large
  small: Large
  medium?: Large
  thumbnail?: Large
}

export interface Large {
  ext: EXT
  url: string
  hash: string
  mime: MIME
  name: string
  path: Path
  size: number
  width: number
  height: number
}

export enum EXT {
  Jpg = '.jpg',
}

export enum MIME {
  ImageJPEG = 'image/jpeg',
}

export enum Path {
  Uploads = 'uploads',
}

export interface Genre {
  id: number
  name: string
}

export enum Type {
  Movie = 'movie',
  Tv = 'tv',
}
export interface UncastHeros {
  id: string
  title: string
  items: UncastContent[]
}
export interface Heros {
  id: string
  title: string
  items: UncastContent[]
}

export interface BelongsToCollection {
  id: number
  name: string
  poster_path: string
  backdrop_path: null
}

export interface ProductionCompany {
  id: number
  name: string
  logo_path: null | string
  origin_country: string
}

export interface ProductionCountry {
  name: string
  iso_3166_1: string
}

export interface SpokenLanguage {
  name: string
  iso_639_1: string
  english_name: string
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class GridConvert {
  public static parsedToGridResponse(obj: any): OdinGridResponse {
    return cast(obj)
  }
}

function castItem(item: UncastContent): Content {
  const {
    release,
    similar: sim = [],
    recommended: rec = [],
    tmdb_data,
    ...rest
  } = item
  const recommended = rec === null ? [] : rec
  const similar = sim === null ? [] : sim
  return {
    release: new Date(release),
    similar: similar.map(castItem),
    recommended: recommended.map(castItem),
    ...rest,
  }
}
export function castGridRow(row: UncastGrid): Grid {
  const { contents, name, ...rest } = row
  return {
    ...rest,
    title: name,
    content: contents.map(castItem),
  }
}
function cast(obj: UncastOdinGridResponse): OdinGridResponse {
  if (!obj) {
    console.error('Unable to cast odin object', obj)
    throw new Error('Unable to parse obj')
  } else {
    const { grid, context } = obj
    const { items, ...rest } = obj.heros

    return {
      context,
      heros: {
        ...rest,
        content: items.map(castItem),
      },
      grid: grid.map(castGridRow),
    }
  }
}
