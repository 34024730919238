import { DeployEnvironments } from '@adiffengine/engine-types'

export const envSettings = {
  appSettings: {
    debug: true,
    env: DeployEnvironments.Development,
    api_url: 'http://localhost:1337/api/valhalla-api',
    PLAUSIBLE_DOMAIN: 'thor.staging.adetv.co',
  },
  platformSettings: {
    log: false,
    showVersion: false,
  },
}
