import { DeployEnvironments, SettingsFile } from '@adiffengine/engine-types'

export const baseSettings: SettingsFile = {
  appSettings: {
    env: 'production' as DeployEnvironments,
    debug: false,
    api_url: 'http://cms.adeengine.com/api/valhalla-api',
    APP_MUX_DEBUG: false,
    APP_SHORT_NAME: 'thor',
    APP_ERROR_CODE: 'THOR',
    version: '1.0.0',
    APP_MUX_ENV_KEY: 'r3bcm6ucvunrp96o3s06a7jg5',
    ADS_ENABLED: false,
    UP_NEXT_TIME_IN_SECONDS: 20,
    UP_NEXT_OFFSET_FROM_END: 3,
    UP_NEXT_DISABLED: false,
    DEBUG_UP_NEXT: false,
    SENTRY_DSN:
      'https://83e766933bd84edab34eac29052f1155@o210186.ingest.sentry.io/4504946330566656',
    FIREBASE_APP_ID: '1:311719570977:web:c449fb696c86f2ea50bd60',
    FIREBASE_MEASUREMENT_ID: 'G-C3PXCZDEF6',
    PLAUSIBLE_DOMAIN: 'thor.adeengine.com',
    FORCE_GA_TAG: true,
    enablePointer: false,
    APP_TIMEOUT_DURATION_IN_MINUTES: 0.5,
    stage: {
      clearColor: '0x00000000',
    },
  },
  platformSettings: {
    path: './',
    log: false,
    clearColor: '0x00000000',
    showVersion: import.meta.env.MODE !== 'production',
    proxyUrl:
      'https://us-central1-valhalla-f2ec8.cloudfunctions.net/feedFM-imageProxy',
    router: {
      backtracking: true,
    },
    inspector: true,
  },
}
