import { envSettings as production } from './settings.production'
import { envSettings as development } from './settings.development'
import { envSettings as staging } from './settings.staging'
import { PartialSettingsFile } from '@adiffengine/engine-types'
const lookup: Record<string, PartialSettingsFile> = {
  production,
  development,
  staging,
}

export const envSettings: PartialSettingsFile =
  lookup[import.meta.env.MODE] ?? production
