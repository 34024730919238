import { DeviceSettings as LGSettings } from './settings.lg'
import { DeviceSettings as SamungSettings } from './settings.samsung'
import { DeviceSettings as X1Settings } from './settings.x1'
import { DeviceSettings as SmartTVSettings } from './settings.smart-tv'
import {
  DeviceType,
  DeviceTypes,
  PartialSettingsFile,
  isValidDeviceType,
} from '@adiffengine/engine-types'
import { safeWindowVar } from '../lib'

const Lookup: Record<DeviceType, PartialSettingsFile> = {
  [DeviceTypes.LG]: LGSettings,
  [DeviceTypes.SmartTV]: SmartTVSettings,
  [DeviceTypes.Android]: SmartTVSettings,
  [DeviceTypes.X1]: X1Settings,
  [DeviceTypes.Samsung]: SamungSettings,
}

export function deviceSettings(
  force?: DeviceType | string,
): PartialSettingsFile {
  let windowType = safeWindowVar('__ADE_SETTINGS_DEVICE')

  console.info('Window Type / Env Type', windowType)

  const type = isValidDeviceType(force)
    ? force
    : isValidDeviceType(windowType)
      ? windowType
      : DeviceTypes.SmartTV
  return Lookup[type]
}
