import {
  AnonymousAuthStatus,
  AuthStatus,
  AuthenticatedAuthStatus,
  DeviceLinkPoller,
  GenerateLinkCodeInput,
  LinkPollInput,
  LinkPollResponse,
  NotAuthenticatedAuthStatus,
  PollerResponse,
  StoreDeviceLink,
  UserSDK,
  isLinkPollSuccess,
} from '@adiffengine/engine-types'

import { Debugger } from '@adiffengine/elements'
import { Registry, Storage } from '@lightningjs/sdk'
import { logEvent as gLogEvent, getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  getAuth,
  signInAnonymously,
  signInWithCustomToken,
  signOut,
} from 'firebase/auth'
import { getFunctions, httpsCallable } from 'firebase/functions'
const debug = new Debugger('thor:firebase')
// eslint-disable-next-line no-unused-vars

export const FIREBASE_USER_DATA_STORAGE_KEY = 'ade/userData'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBLysQcbfo9rIQga4qi8Kkm-OWuLoSsl8g',
  authDomain: 'valhalla-f2ec8.firebaseapp.com',
  projectId: 'valhalla-f2ec8',
  storageBucket: 'valhalla-f2ec8.appspot.com',
  messagingSenderId: '311719570977',
  appId: '1:311719570977:web:d23dc6019bf79af050bd60',
  measurementId: 'G-JBKV8GC7H4',
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export type AnalyticsEvent = Parameters<typeof gLogEvent>[1]
export type AnalyticsEventParams = Parameters<typeof gLogEvent>[2]
export function logEvent(event: AnalyticsEvent, params?: AnalyticsEventParams) {
  gLogEvent(analytics, event, params)
}
const functions = getFunctions(app)

const deviceCode = httpsCallable<GenerateLinkCodeInput, StoreDeviceLink>(
  functions,
  'LinkingFunctions-generateLinkCode',
)
const linkPollCaller = httpsCallable<LinkPollInput, LinkPollResponse>(
  functions,
  'LinkingFunctions-linkPoller',
)

async function linkPoll(input: LinkPollInput) {
  const result = await linkPollCaller(input)
  return result.data
}

export async function getDeviceCode(
  input: GenerateLinkCodeInput,
): Promise<StoreDeviceLink> {
  const response = await deviceCode(input)
  return response.data
}

export async function logOut(): Promise<NotAuthenticatedAuthStatus> {
  await Promise.all([signOut(auth), removeUserData()])
  return {
    isAnonymous: false,
    status: 'notauthenticated',
  }
}

export async function authenticateAnonymously(): Promise<
  AnonymousAuthStatus | NotAuthenticatedAuthStatus
> {
  try {
    const result = await signInAnonymously(auth)
    const userStatus: AnonymousAuthStatus = {
      uid: result.user.uid,
      isAnonymous: true,
      status: 'anonymous',
    }
    return userStatus
  } catch (error) {
    console.warn('Anonymous auth error so not authenticated')
    const out: NotAuthenticatedAuthStatus = {
      isAnonymous: false,
      status: 'notauthenticated',
    }
    return out
  }
}

export async function initializeUser(): Promise<AuthStatus> {
  const userData = await getUserData()
  debug.info('Got user data?', userData)
  if (userData !== null) {
    try {
      const result = await signInWithCustomToken(auth, userData.token)
      const out: AuthenticatedAuthStatus = {
        uid: result.user.uid,
        isAnonymous: false,
        displayName: result.user.displayName,
        status: 'user',
      }
      return out
    } catch (e) {
      console.warn('Error logging in user with custom token %s', e.message, {
        error: e,
      })
      return authenticateAnonymously()
    }
  } else {
    return authenticateAnonymously()
  }
}
export interface UserDataStore {
  uid: string
  token: string
}
export async function saveUserData(data: UserDataStore) {
  Storage.set(FIREBASE_USER_DATA_STORAGE_KEY, data)
  return data
}
export async function getUserData(): Promise<UserDataStore | null> {
  const data = Storage.get(FIREBASE_USER_DATA_STORAGE_KEY)
  return data ? (data as UserDataStore) : null
}

export async function removeUserData(): Promise<void> {
  Storage.remove(FIREBASE_USER_DATA_STORAGE_KEY)
}

export class LinkPoller implements DeviceLinkPoller {
  private _state: 'inactive' | 'polling' | 'canceled' = 'inactive'
  public readonly deviceId: string
  public readonly linkCode: string
  constructor(deviceId: string, linkCode: string) {
    this.deviceId = deviceId
    this.linkCode = linkCode
  }
  private set state(state: 'inactive' | 'polling' | 'canceled') {
    console.info('set state to %s', state)
    this._state = state
  }
  public get state() {
    return this._state
  }

  private _canceled = false
  private _timeout: ReturnType<typeof setTimeout> | null = null
  private async _checkForLink() {
    const result = await linkPoll({
      deviceId: this.deviceId,
      linkCode: this.linkCode,
    })
    return result
  }
  private _reset(canceled = false) {
    if (this._timeout != null) Registry.clearTimeout(this._timeout)
    this._canceled = canceled
  }
  async poll(interval = 4000, timeout = 60000) {
    this._reset()
    const over = new Date().getTime() + timeout
    return new Promise<PollerResponse>((resolve, reject) => {
      this.state = 'polling'
      const checkForLink = async () => {
        try {
          const result = await this._checkForLink()
          if (!this._canceled) {
            if (isLinkPollSuccess(result)) {
              return signInWithCustomToken(auth, result.token)
                .then(userCredential => {
                  console.info('Logged in user with credential')
                  return saveUserData({
                    uid: userCredential.user.uid,
                    token: result.token,
                  })
                    .catch(e => {
                      console.warn('Error saving user data %s', e.message, {
                        error: e,
                      })
                      reject(e)
                    })
                    .finally(() => {
                      resolve({ uid: userCredential.user.uid })
                    })
                })
                .catch(e => {
                  reject(e)
                })
            }
            resolve(result)
          }
          this.state = 'inactive'
        } catch (error) {
          const errorDetails = JSON.parse(JSON.stringify(error))
          const now = new Date().getTime()
          if (errorDetails.details?.code === 'not-linked' && now < over) {
            this._timeout = setTimeout(checkForLink, interval)
            console.error(error)
          } else {
            if (now >= over) console.info('Polling Timed Out')
            this.state = 'inactive'
            reject(error)
          }
        }
      }
      checkForLink()
    })
  }
  cancel() {
    this._reset(true)
    this.state = 'canceled'
  }
}

export const userSdk: UserSDK = {
  initializeUser,
  authenticateAnonymously,
  LinkPoller,
  getDeviceCode,
  logOut,
}
