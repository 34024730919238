const baseOptions = {
  maxItems: 20,
}
export type MemoryCacheOptions = typeof baseOptions
export class MemoryCache<T = any> {
  private _items: Record<string, CacheItem<T>> = {} as Record<
    string,
    CacheItem<T>
  >
  private _lastCache: number = 0
  static opts: MemoryCacheOptions = baseOptions
  public readonly options

  constructor(
    cache?: Record<string, CacheItem<T>>,
    options: Partial<MemoryCacheOptions> = {},
  ) {
    this.options = { ...baseOptions, options }
    if (cache) {
      this._items = cache
      this._lastCache = Object.entries(cache).reduce((acc, [_k, v]) => {
        return v.cacheIndex > acc ? v.cacheIndex : acc
      }, -1)
    }
  }

  set(key: string, item: T) {
    this._items[key] = {
      cacheIndex: ++this._lastCache,
      item,
    }
    while (this.length > this.options.maxItems) {
      this.deleteEldest()
    }
  }

  get(key: string): T | null {
    return this._items[key]?.item ?? null
  }
  get length(): number {
    return Object.keys(this._items).length
  }

  deleteEldest() {
    const id = this.getEldest()
    if (id !== null) delete this._items[id]
  }

  getEldest() {
    const eldest = Object.entries(this._items).reduce(
      (acc, [k, v]) => {
        return v.cacheIndex < acc.index ? { index: v.cacheIndex, id: k } : acc
      },
      { index: Infinity, id: null } as { index: number; id: string | null },
    )
    return eldest.id
  }
}
interface CacheItem<T = any> {
  cacheIndex: number
  item: T
}
