import {
  DeployEnvironments,
  PartialSettingsFile,
} from '@adiffengine/engine-types'

export const envSettings: PartialSettingsFile = {
  appSettings: {
    env: DeployEnvironments.Staging,
  },
}
