/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/// <reference path="../../../libs/lightning/elements/src/augmentation.d.ts" />
import {
  ContentEpisode,
  ContentItem,
  ContentSource,
  ID,
  MediaDetails,
  MediaLookupType,
  PollerResponse,
  ThemeConfig,
} from '@adiffengine/engine-types'
// word.
import {
  Colors,
  Settings as LightningSettings,
  Router,
  Utils,
} from '@lightningjs/sdk'

import {
  AdePlayerPlane,
  Debugger,
  HeroWidget,
  MainMenu,
  MemoryCache,
  MenuButtons,
  Settings,
  SettingsStore,
  StandardBackground,
  ThemeFont,
  ThorApp,
  getStoredTheme,
  getThemeFonts,
  isGoodArray,
  isGoodNumber,
} from '@adiffengine/elements'
import { RoutedApp } from '@lightningjs/sdk/src/Router/base'

import { default as isEqual } from 'fast-deep-equal/es6'
import isString from 'lodash/isString'
import { saveToWatchlist } from './lib/db'
import { LinkPoller, getDeviceCode } from './lib/firebase'
import { getAppropriateSource, getSdk } from './lib/thorStrapiSdk'
import { getRouteConfig } from './thor_route_config'

export interface AppTemplateSpec extends Router.App.TemplateSpec {
  Background: typeof StandardBackground
  Widgets: {
    VideoPlayer: typeof AdePlayerPlane
    MainMenu: typeof MainMenu
    HeroWidget: typeof HeroWidget
  }
  Fader: object
  theme: ThemeConfig
  themeColor: string
}

export interface AppTemplateEvents extends Router.App.EventMap {
  themeChanged(theme: ThemeConfig, oldTheme?: ThemeConfig): void
}
export interface AppTemplateConfig extends RoutedApp.TypeConfig {
  EventMapType: AppTemplateEvents
}
export interface AppTemplateType extends Router.App.TypeConfig {
  EventMapType: AppTemplateEvents
}

const debug = new Debugger('APP')
debug.enabled = true

export class App extends ThorApp {
  private _theme: ThemeConfig | null = getStoredTheme()

  async _setup() {
    await super._setup()

    this.MainMenu.patch({
      menuButtons: MenuButtons,
    })
    this.Widgets.setSmooth('alpha', 1)
  }
  override _construct() {
    super._construct()
    this.themeChanged = this.themeChanged.bind(this)
    this.stage.application.on('themeChanged', this.themeChanged)
  }

  private _cache: MemoryCache | null = null

  get cache(): MemoryCache {
    if (this._cache === null) {
      this._cache = new MemoryCache()
    }
    return this._cache
  }
  static colors() {
    const theme = getStoredTheme()
    debug.info('Theme! %s  %s', theme.name, theme.palette.background)
    const colors = Object.entries(theme.palette).reduce((acc, [k, v]) => {
      if (isString(k) && isString(v)) acc[k] = v
      return acc
    }, {} as Record<string, string>)
    return colors
  }

  _menuRight() {
    Router.focusPage()
  }

  themeChanged(currentTheme: ThemeConfig, oldTheme?: ThemeConfig) {
    if (oldTheme) {
      this.animation({
        duration: 0.2,
        actions: [
          {
            t: 'Background.Bubble',
            //@ts-ignore
            p: 'shader.outerColor',
            v: {
              0: Colors(oldTheme.palette.background).get(),
              1: Colors(currentTheme.palette.background).get(),
            },
          },
          {
            t: 'Background.Bubble',
            //@ts-ignore
            p: 'shader.innerColor',
            v: {
              0: Colors(oldTheme.palette.backgroundGradient).get(),
              1: Colors(currentTheme.palette.backgroundGradient).get(),
            },
          },
        ],
      }).start()
    }
  }

  static getFonts() {
    const theme = getStoredTheme()
    const demoMode = LightningSettings.get(
      'app',
      'demoMode',
      'false',
    ).toString()
    return Object.entries(theme.fonts)
      .filter(([family]) => family !== 'name')
      .map(([family, url]) => ({
        family,
        url: Utils.asset(url as string),
      }))
      .concat(
        demoMode === 'true'
          ? getThemeFonts().map(
              ({ url, ...rest }): ThemeFont => ({
                url: Utils.asset(url),
                ...rest,
              }),
            )
          : [],
      )
  }

  _count = 0
  async $getDeviceCode() {
    const deviceId = await this.lifecycleHandler.getUserId()
    const response = await getDeviceCode({
      deviceId,
      deviceType: navigator.userAgent,
    })
    return response
  }

  async $search(term: string, min = 3): Promise<ContentItem[] | null> {
    if (`${term ? term.trim() : ''}`.length < min) return null
    this.stage.application.emit('searching', term)
    this.setInitialQuery(term)
    const odinClient = getSdk()
    const results = await odinClient.search(term)
    if (isGoodArray<ContentItem>(results)) {
      this.stage.application.emit('searched', results.length)
      return results
    } else {
      this.stage.application.emit('searched', 0)
      return null
    }
  }
  $cache<T = any>(action: 'get', payload: { key: string }): T
  $cache<T = any>(action: 'set', payload: { key: string; item: T }): void
  $cache<T = any>(
    action: 'set' | 'get',
    payload: { key: string; item?: T },
  ): T | void {
    const { key, item } = payload
    if (action === 'set') this.cache.set(key, item)
    else return this.cache.get(key) as T
  }

  set background(str: string) {
    this.theme.palette.background = str
  }

  get background() {
    return this.theme.palette.background
  }

  getRouteConfig(initialHash: string | null) {
    const routeConfig = getRouteConfig(this, initialHash)
    return routeConfig
  }

  get theme(): ThemeConfig {
    if (!this._theme) {
      this._theme = getStoredTheme()
    }
    return this._theme as ThemeConfig
  }

  set theme(theme: ThemeConfig | null) {
    const newTheme: ThemeConfig = theme === null ? getStoredTheme() : theme
    if (!isEqual(newTheme, this._theme)) {
      const old = this._theme ? ({ ...this._theme } as ThemeConfig) : undefined
      this._theme = newTheme
      this.emit('themeChanged', this._theme, old)
    }
  }

  $theme() {
    return this.theme
  }
  $app() {
    return this
  }

  set themeColor(str: string) {
    this.theme.palette.text = str
  }

  private _currentContent: ContentItem | null = null
  $currentContent(id: string): typeof this._currentContent {
    if (this._currentContent && this._currentContent.id === id)
      return this._currentContent
    return null
  }

  $currentFocusContent(content: ContentItem | null): void {
    this.tag('Widgets.HeroWidget')?.setContent(content)
  }

  $setting<T extends keyof Settings>(key: T): Settings[T]
  $setting<T extends keyof Settings>(key: T, value: Settings[T]): Settings[T]
  $setting<T extends keyof Settings>(key: T, value?: Settings[T]): Settings[T] {
    if (value !== undefined) {
      SettingsStore.set(key, value)
      return value
    } else {
      return SettingsStore.get(key)
    }
  }
  $play(contentItem: ContentItem) {
    this._currentContent = contentItem
  }
  private _tracker: Record<string, number> = {}

  $trackFocus(path: string, idx?: number | null): number | null {
    if (idx === null) {
      delete this._tracker[path]
      return null
    }
    if (isGoodNumber(idx, true)) {
      this._tracker[path] = idx
    }
    return this._tracker[path] ?? null
  }

  $setTheme(theme: ThemeConfig) {
    if (theme) {
      this.theme = theme
    }
  }

  $fetchSeason(id: ID, number?: ID) {
    debug.info('Fetching season')
    const odinClient = getSdk()
    return odinClient.seasonDetails(id, number).then(r => {
      console.info('Fetching Season Response', r)
      return r
    })
  }
  $fetchPerson(id: ID) {
    const odinClient = getSdk()
    return odinClient.person(id).then(p => {
      console.info('Got P', p)
      return p
    })
  }

  $getThemeColor() {
    return this.themeColor
  }

  // Hi
  $fetchEpisode(id: ContentItem['id']): Promise<ContentEpisode | null> {
    const odinClient = getSdk()
    return odinClient.episodeDetails(id)
  }

  $getAppropriateSource(sources?: ContentSource[]): ContentSource | null {
    return getAppropriateSource(sources)
  }

  $addToWatchlist(content: ContentItem, remove = false) {
    debug.info(`${remove ? 'Removing' : 'Adding'} %s to watchlist`, content)
    try {
      return saveToWatchlist(content, remove)
    } catch (error) {
      console.warn('Error saving to watchlist %s', error.message, error)
      return false
    }
  }
  /*
  Player Functionss
  */
  $fetchSource(
    contentItem: ContentItem,
    lookup: MediaLookupType,
  ): Promise<MediaDetails | null> {
    const odinClient = getSdk()
    return odinClient.getSource(contentItem, lookup)
  }

  /* Poller Functions */

  $pollForCode(
    deviceId: string,
    linkCode: string,
    pollingInterval = 4000,
  ): Promise<PollerResponse> {
    if (!isString(deviceId) || !isString(linkCode))
      throw new Error(
        `Invalid params to $pollForCode, deviceId ("${deviceId}") and linkCode ("${linkCode}") are required`,
      )
    if (this._poller) this._poller.cancel()
    this._poller = new LinkPoller(deviceId, linkCode)
    return this._poller.poll(pollingInterval)
  }

  $cancelPolling() {
    if (this._poller) {
      this._poller.cancel()
      this._poller = null
    }
  }

  /* End Poller Functions */
}
export type AppType = typeof App
