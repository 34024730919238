import { envSettings } from './envs'
import { mergeConfig, deviceSettings } from '@adiffengine/elements'
import { baseSettings } from './base-settings'

console.info('Env Settings for merge config', envSettings)

export const settings = mergeConfig(
  baseSettings,
  envSettings,
  deviceSettings(import.meta.env.VITE_FORCE_DEVICE_CONFIG),
)
